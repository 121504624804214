import React, { ChangeEvent, useEffect, useState } from "react"
import { useAppDispatch } from "../../../hooks/redux-hooks/ReduxHooks"
import { uploadImages } from "../../../store/slices/file-upload"
import { BASE_URL } from "../../../constants"
import RingLoader from "../../shared/loader/ring-loader/RingLoader"
import AddImageIcon from "../../../assets/images/svg/add-image.svg?react"
import EditIcon from "../../../assets/images/svg/edit-icon.svg?react"
import DeleteIcon from "../../../assets/images/svg/trash-icon.svg?react"
import { IObjectKeys } from "../../../types"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"

const MAX_FILE_SIZE_MB = 5
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024

const ThumbnailUploading = ({
  setThumbnailId,
  editMode = false,
  defaultValue,
}: {
  setThumbnailId: React.Dispatch<React.SetStateAction<number | null>>
  editMode?: boolean
  defaultValue?: IObjectKeys
}) => {
  const dispatch = useAppDispatch()
  const [thumbnail, setThumbnail] = useState<string>("")
  const [fileInput, setFileInput] = useState<HTMLInputElement | null>(null)
  const [thumbnailLoading, setThumbnailLoading] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      if (!file.type.startsWith("image/")) {
        enqueueSnackbar(t("fileTypeErr"), {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 3000,
        })
        e.target.value = ""
        return
      }
      if (file.size > MAX_FILE_SIZE_BYTES) {
        enqueueSnackbar(t("fileSizeErr"), {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 3000,
        })
        e.target.value = ""
        return
      }

      setThumbnailLoading(true)
      const response: any = await dispatch(uploadImages(file))
      setThumbnail(`${BASE_URL}/${response?.payload?.url}`)
      setThumbnailId(response?.payload?.id)
      e.target.value = ""
      setThumbnailLoading(false)
    }
  }

  const deleteFile = () => {
    setThumbnail("")
    if (fileInput) {
      fileInput.value = ""
    }
  }

  const handleEditFile = () => {
    if (fileInput) {
      fileInput.click()
    }
  }

  useEffect(() => {
    if (editMode && defaultValue) {
      setThumbnail(`${BASE_URL}${defaultValue.url}`)
      setThumbnailId(defaultValue.id)
    }
  }, [editMode, defaultValue])

  return (
    <div className={"add-offer-form__item d-flex-column gap-12"}>
      <p className={"add-offer-form__item--title"}>
        {t("offerCover")}
        <span className={"add-offer-form__item--title--required"}>*</span>
      </p>
      <label
        htmlFor='thumbnail'
        className={
          "d-flex-centered add-offer-form__item--upload add-offer-form__item--upload-thumbnail"
        }
      >
        <input
          type='file'
          accept='image/*'
          name='thumbnail'
          id='thumbnail'
          onChange={uploadFile}
          ref={(input) => setFileInput(input)} // Set reference to input
          multiple={false}
        />

        {thumbnailLoading ? (
          <RingLoader />
        ) : thumbnail ? (
          <div className='thumbnail-preview'>
            <div className={"thumbnail-preview-img"}>
              <img src={thumbnail} alt='Thumbnail Preview' />
            </div>
          </div>
        ) : (
          <AddImageIcon />
        )}
      </label>
      {thumbnail ? (
        <div className={"portfolio-uploading-image-wrapper-btns d-flex-center-aligned"}>
          <button type={"button"} className={"d-flex-centered"} onClick={handleEditFile}>
            <span>{t("edit")}</span>
            <EditIcon />
          </button>
          <button type={"button"} className={"d-flex-centered"} onClick={deleteFile}>
            <span>{t("delete")}</span>
            <DeleteIcon />
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default ThumbnailUploading

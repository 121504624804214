import React, { useCallback, useState } from "react"
import AddOfferForm from "../../components/add-offer-form/AddOfferForm"
import LeftArrow from "../../assets/images/svg/left-arrow.svg?react"
import "./styles.scss"
import { useNavigate } from "react-router-dom"
import OfferSuccessSubtext from "../../assets/images/svg/offer-success-subtext.svg?react"
import AddOfferBtn from "../../components/shared/add-offer-btn/AddOfferBtn"
import { useTranslation } from "react-i18next"
const AddOfferPage = () => {
  const { t } = useTranslation()
  const [successVisible, setSuccessVisible] = useState<boolean>(false)
  const navigate = useNavigate()
  const handleGoBack = useCallback(() => {
    navigate(-1)
  }, [])

  return (
    <div className={`add-offer ${successVisible ? "p-b-0" : ""}`}>
      <div className={"add-offer__heading d-flex-centered"}>
        <button className={"d-flex-centered add-offer__heading--go-back"} onClick={handleGoBack}>
          <LeftArrow />
        </button>
        <h1 className={"add-offer__heading--title"}>{t("newOffer")}</h1>
      </div>
      {successVisible ? (
        <div className={"add-offer__success-wrapper d-flex-centered"}>
          <div className={"add-offer__success"}>
            <div className={"add-offer__success-text d-flex-column-centered gap-6"}>
              <h2>{t("thanks")}</h2>
              <p>
                {t("yourOfferThanks1")} <br /> {t("yourOfferThanks2")}
              </p>
            </div>
            <div className={"add-offer__success-icon"}>
              <OfferSuccessSubtext />
            </div>
            <div className={"add-offer__success-btn"}>
              <AddOfferBtn
                type={"button"}
                handleClick={() => setSuccessVisible(false)}
                text={"Добавить ещё"}
              />
            </div>
          </div>
        </div>
      ) : (
        <AddOfferForm setSuccessVisible={setSuccessVisible} />
      )}
    </div>
  )
}

export default AddOfferPage

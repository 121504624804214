import React from "react"
import EditIcon from "../../../../assets/images/svg/tab-edit-icon.svg?react"
import TrashIcon from "../../../../assets/images/svg/tab-trash-bin.svg?react"

const MyOfferTabs = () => {
  return (
    <>
      <div className='swipe-right-tab d-flex-column-centered my-offers-edit-tab'>
        <EditIcon />
      </div>
      <div className='swipe-left-tab  d-flex-column-centered my-offers-delete-tab'>
        <TrashIcon />
      </div>
    </>
  )
}

export default MyOfferTabs

import axios, { AxiosInstance } from "axios"

// Optionally, define the structure of your API response
interface ApiResponse<T> {
  data: T
  status: number
  message?: string
}

// Create an axios instance
const api: AxiosInstance = axios.create({
  baseURL: "https://api.example.com", // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
})

// Set x-lang header in a request interceptor to ensure it gets the latest value
api.interceptors.request.use(
  (config) => {
    // Retrieve language from localStorage
    config.headers["x-lang"] = localStorage.getItem("language") || "ru" // Set x-lang header
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// Optional: Interceptor to log responses or handle errors globally
api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.error("API Error:", error)
    return Promise.reject(error)
  },
)

export default api

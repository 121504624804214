import React, { useCallback } from "react"
import { useAppSelector } from "../../../hooks/redux-hooks/ReduxHooks"
import { getSingleOffer } from "../../../store/slices/offers"
import TelegramIcon from "../../../assets/images/svg/telegram-icon.svg?react"
import VerifyIcon from "../../../assets/images/svg/small-verify.svg?react"
import RightArrowIcon from "../../../assets/images/svg/white-right-arrow.svg?react"
import SuitcaseIcon from "../../../assets/images/svg/suitcase-single.svg?react"
import { BASE_URL } from "../../../constants"
import { useNavigate } from "react-router-dom"
import "./SingleOfferProfile.scss"
import { useTranslation } from "react-i18next"

const SingleOfferProfile = ({ isMe }: { isMe: boolean }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const offer = useAppSelector(getSingleOffer)

  const handleClickOnOtherOffers = useCallback(() => {
    if (isMe) {
      navigate("/offers")
    } else {
      navigate(`/other-offers?telegramId=${offer?.freelancer.telegramId}`)
    }
  }, [isMe])

  return (
    <div className={"offer-user-profile"}>
      <div className={"d-flex-space-between-aligned offer-user-profile__user"}>
        <div
          className={"d-flex-center-aligned gap-8 flex-1"}
          onClick={() => {
            if (isMe) {
              navigate("/more")
            } else {
              navigate(`/freelancer-profile?telegramId=${offer?.freelancer.telegramId}`)
            }
          }}
        >
          <div className={"offer-user-profile__user-img"}>
            <img src={`${BASE_URL}/${offer?.freelancer?.avatarUrl}`} alt='Avatar' />
          </div>
          <div className={"offer-user-profile__user-name d-flex-center-aligned gap-8"}>
            <VerifyIcon />
            <p>{offer?.freelancer?.username}</p>
          </div>
        </div>
        {offer?.freelancer?.allowsWriteToPm ? (
          <button
            className={"d-flex-center-aligned gap-6 offer-user-profile__user-pm-btn"}
            onClick={() => {
              const url = `https://t.me/${offer.freelancer.username}`
              window.location.href = url
            }}
          >
            <TelegramIcon />
            <span>{t("write")}</span>
          </button>
        ) : null}
      </div>
      <div className={"w-100 d-flex-column gap-8 offer-user-profile__actions"}>
        <button className={"d-flex-space-between-aligned"} onClick={handleClickOnOtherOffers}>
          <span className={"d-flex-center-aligned gap-6"}>
            <SuitcaseIcon />
            <span>{isMe ? t("myOffers") : t("otherFreelancerOffers")}</span>
          </span>
          <RightArrowIcon />
        </button>
      </div>
    </div>
  )
}

export default SingleOfferProfile

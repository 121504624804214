import React, { useCallback } from "react"
import VerifiedIcon from "../../assets/images/svg/verify-icon.svg?react"
import ContactIcon from "../../assets/images/svg/contact-icon.svg?react"
import GuardIcon from "../../assets/images/svg/profile-cover.svg?react"
import SuitcaseIcon from "../../assets/images/svg/suitcase-icon.svg?react"
import RightArrowIcon from "../../assets/images/svg/right-arrow.svg?react"
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { fetchFreelancerByTelegramIdFromProfile, getUser } from "../../store/slices/auth"
import "./styles.scss"
import { BASE_URL } from "../../constants"
import { FreelancerType } from "../../enums"
import AddOfferBtn from "../../components/shared/add-offer-btn/AddOfferBtn"
import { useNavigate } from "react-router-dom"
import LanguageSelect from "../../components/language-select/LanguageSelect"
import { useTranslation } from "react-i18next"

const ProfilePage = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleButtonClick = useCallback(
    (userType: any) => {
      dispatch(fetchFreelancerByTelegramIdFromProfile({ userType, telegramId: user.telegramId }))
    },
    [user],
  )

  const handleOpenMyOffers = useCallback(() => {
    navigate("/offers")
  }, [])

  const handleOppenHiddenPage = useCallback(() => {
    navigate("/hidden")
  }, [])

  return (
    <div className={"profile-page d-flex-column"}>
      <div className={"d-flex-space-between-aligned profile-page__heading"}>
        <div className={"d-flex-center-aligned profile-page__heading--info gap-8"}>
          <VerifiedIcon />
          <p>{user.username}</p>
        </div>
        <div className={"d-flex-center-aligned gap-8"}>
          <LanguageSelect />
          <button
            className={"d-flex-centered profile-page__heading--contact"}
            onClick={() => {
              window.location.href = `https://t.me/the010012`
            }}
          >
            <ContactIcon />
          </button>
        </div>
      </div>
      <div className={"profile-page__picture d-flex-column"}>
        <div className={"profile-page__picture--cover"}>
          <GuardIcon />
        </div>
        <div className={"profile-page__picture--img"}>
          <img src={`${BASE_URL}/${user?.avatarUrl}`} alt='avatar' />
        </div>
        <div className={"profile-page__picture--actions d-flex-center-aligned gap-8"}>
          <button
            className={`${user.type === FreelancerType.FREELANCER ? "profile-page__picture--actions-active" : ""}`}
            onClick={() => handleButtonClick(FreelancerType.FREELANCER)}
          >
            {t("myStatus")}
          </button>
          <button
            className={`${user.type === FreelancerType.CLIENT ? "profile-page__picture--actions-active" : ""}`}
            onClick={() => handleButtonClick(FreelancerType.CLIENT)}
          >
            {t("myStatusBuyer")}
          </button>
        </div>
      </div>
      <div className={"profile-page__content d-flex-column"}>
        {user?.type === FreelancerType.FREELANCER ? <AddOfferBtn type={"button"} /> : null}
        <div className={"profile-page__content__list d-flex-column"}>
          <h2 className={"profile-page__content__list--title"}>Биржа</h2>
          <div className={"d-flex-column gap-6"}>
            {user?.type === FreelancerType.FREELANCER ? (
              <button
                className={"d-flex-space-between-aligned profile-page__content__list--item"}
                onClick={handleOpenMyOffers}
              >
                <span className={"d-flex-center-aligned gap-8"}>
                  <SuitcaseIcon />
                  <span>{t("myOffers")}</span>
                </span>
                <RightArrowIcon />
              </button>
            ) : null}
            <button
              className={"d-flex-space-between-aligned profile-page__content__list--item"}
              onClick={handleOppenHiddenPage}
            >
              <span className={"d-flex-center-aligned gap-8"}>
                <SuitcaseIcon />
                <span>{t("hiddenOffers")}</span>
              </span>
              <RightArrowIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePage

import React, { useEffect, useState } from "react"
import { fetchCategories, getCategories } from "../../../store/slices/categories"
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks/ReduxHooks"
import SelectArrowUp from "../../../assets/images/svg/select-arrow-up.svg?react"
import SelectArrowDown from "../../../assets/images/svg/select-arrow-down.svg?react"
import SelectArrowRight from "../../../assets/images/svg/select-arrow-right.svg?react"
import SelectArrowLeft from "../../../assets/images/svg/select-arrow-left.svg?react"
import "./CategoriesSelect.scss"
import { IObjectKeys } from "../../../types"
import { useTranslation } from "react-i18next"

interface SubCategory {
  id: number
  name: string
  categoryId: number
}

interface Category {
  id: number
  name: string
  subCategories: SubCategory[]
}

const CategoriesSelect = ({
  setSubCategoryId,
  editMode,
  defaultValue,
}: {
  setSubCategoryId: React.Dispatch<React.SetStateAction<number | null>>
  editMode?: boolean
  defaultValue?: IObjectKeys
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const categories: Category[] = useAppSelector(getCategories)
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<number | null>(null)
  const [expandedCategoryId, setExpandedCategoryId] = useState<number | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  useEffect(() => {
    dispatch(fetchCategories({ withSubcategories: true }))
  }, [dispatch])

  const toggleCategory = (categoryId: number) => {
    if (expandedCategoryId === categoryId) {
      setExpandedCategoryId(null)
    } else {
      setExpandedCategoryId(categoryId)
    }
  }

  const handleSubCategorySelect = (subCategoryId: number) => {
    setSelectedSubCategoryId(subCategoryId)
    setSubCategoryId(subCategoryId)
    // setExpandedCategoryId(null);
  }

  const selectedSubCategory = categories
    ?.flatMap((category: Category) => category.subCategories)
    ?.find((sub: SubCategory) => sub?.id === selectedSubCategoryId)

  useEffect(() => {
    if (editMode && defaultValue) {
      setSubCategoryId(defaultValue.id)
      setSelectedSubCategoryId(defaultValue.id)
    }
  }, [editMode, defaultValue])
  return (
    <div className='categories-select'>
      <div
        className='categories-select__selected d-flex-space-between-aligned'
        onClick={() => setOpen((prevState) => !prevState)}
      >
        {selectedSubCategory ? (
          <p>{selectedSubCategory.name}</p>
        ) : (
          <p>{t("categoryNotSelected")}</p>
        )}
        <span>{expandedCategoryId !== null ? <SelectArrowUp /> : <SelectArrowDown />}</span>
      </div>
      {open && (
        <div className='categories-select__list d-flex-column gap-8'>
          {categories?.map((category: Category) => (
            <div key={category.id} className='categories-select__list__item'>
              <div
                onClick={() => toggleCategory(category.id)}
                className='categories-select__list__item--category d-flex-space-between-aligned'
              >
                {category.name}{" "}
                {expandedCategoryId === category.id ? <SelectArrowLeft /> : <SelectArrowRight />}
              </div>
              {expandedCategoryId === category.id && category.subCategories.length > 0 && (
                <ul className='categories-select__list__item--subCategories d-flex-column gap-8'>
                  {category.subCategories.map((sub: SubCategory) => (
                    <li
                      key={sub.id}
                      className={`categories-select__list__item--subCategory ${
                        selectedSubCategoryId === sub.id
                          ? "categories-select__list__item--subCategory-active"
                          : ""
                      }`}
                      onClick={() => handleSubCategorySelect(sub.id)}
                    >
                      <label>
                        <input
                          type='radio'
                          checked={selectedSubCategoryId === sub.id}
                          onChange={() => handleSubCategorySelect(sub.id)}
                          style={{ display: "none" }}
                        />
                        {sub.name}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CategoriesSelect

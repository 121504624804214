import "./App.scss"
import "./assets/styles/base.scss"
import { BrowserRouter, useNavigate } from "react-router-dom"
import Routers from "./routes"
import { useEffect, useRef } from "react"
import ScrollToTop from "./hooks/scroll-to-top"
import { Provider } from "react-redux"
import { setupStore } from "./store/store"
import { SnackbarProvider } from "notistack"

const App = () => {
  const store = setupStore()
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <SnackbarProvider maxSnack={3}>
          <Routers />
        </SnackbarProvider>
      </BrowserRouter>
    </Provider>
  )
}

export default App

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import ru from "./locales/ru/translation.json"
import en from "./locales/en/translation.json"
// declare type definitions for the resources
declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: "translation"
    resources: {
      en: typeof en
      ru: typeof ru
    }
  }
}

i18n
  .use(initReactI18next) // Passes i18n instance to react-i18next.
  .init({
    resources: {
      en: {
        translation: en,
      },
      ru: {
        translation: ru,
      },
    },
    lng: "ru", // Default language
    fallbackLng: "ru", // Fallback to English if no translation is available
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  })

export default i18n

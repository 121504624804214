import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { IObjectKeys } from "../../../types"
import { AxiosResponse } from "axios"
import api from "../../../api/api"
import { BASE_API_URL } from "../../../constants"

interface Categories {
  categories: any[]
  categoriesLoading: boolean
  subCategories: any[]
  subCategoriesLoading: boolean
}

const initialState: Categories = {
  categories: [],
  categoriesLoading: true,
  subCategories: [],
  subCategoriesLoading: true,
}

export const fetchCategories = createAsyncThunk<any, { withSubcategories: boolean }>(
  "app/fetchCategories",
  async ({ withSubcategories }): Promise<any> => {
    const response: AxiosResponse<any> = await api.get(`${BASE_API_URL}categories`, {
      params: { withSubCategories: withSubcategories },
    })
    return response.data
  },
)

export const fetchSubCategories = createAsyncThunk<any, number>(
  "app/fetchSubCategories",
  async (categoryId: number): Promise<any> => {
    const response: AxiosResponse<any> = await api.get(
      `${BASE_API_URL}categories/${categoryId}/sub-categories`,
    )
    return response.data
  },
)

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.categoriesLoading = true
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categoriesLoading = false
        state.categories = action.payload
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.categoriesLoading = false
      })
      .addCase(fetchSubCategories.pending, (state) => {
        state.subCategoriesLoading = true
      })
      .addCase(fetchSubCategories.fulfilled, (state, action) => {
        state.subCategoriesLoading = false
        state.subCategories = action.payload
      })
      .addCase(fetchSubCategories.rejected, (state) => {
        state.subCategoriesLoading = false
      })
  },
  reducers: {},
})

export const {} = categoriesSlice.actions
export const getCategories = (state: IObjectKeys) => state.categories.categories
export const getSubCategories = (state: IObjectKeys) => state.categories.subCategories
export const subCategoriesLoading = (state: IObjectKeys) => state.categories.subCategoriesLoading
export const categoriesLoading = (state: IObjectKeys) => state.categories.categoriesLoading

export default categoriesSlice.reducer

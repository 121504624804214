const debounce = (fn: any, wait: number) => {
  let timeoutID: string | number | NodeJS.Timeout | undefined
  return (...arg: any) => {
    if (timeoutID) clearTimeout(timeoutID)
    timeoutID = setTimeout(() => fn(...arg), wait)
  }
}
export const debounced500 = debounce((fn: () => any) => fn(), 500)

//usage example debounced500(() => {
//         Your function
//       })

import "./style.scss"
import BgLogo from "../../assets/images/svg/login-bg-logo.svg?react"
import Logo from "../../assets/images/svg/login-logo.svg?react"
import Freelancer from "../../assets/images/svg/freelancer.svg?react"
import Buyer from "../../assets/images/svg/buyer.svg?react"
import { useNavigate } from "react-router-dom"
import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { fetchFreelancerByTelegramId } from "../../store/slices/auth"
import { FreelancerType } from "../../enums"

const LoginPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { user } = useAppSelector((state) => state.auth) // Get loading and user state from Redux

  const handleFetchFreelancer = useCallback(
    async (userType: FreelancerType) => {
      if (user?.telegramId) {
        const resultAction = await dispatch(
          fetchFreelancerByTelegramId({ telegramId: user.telegramId, userType }),
        )
        if (fetchFreelancerByTelegramId.fulfilled.match(resultAction)) {
          navigate("/catalog")
        } else {
          console.error("Failed to fetch freelancer")
        }
      }
    },
    [dispatch, navigate, user],
  )

  return (
    <div className={"login-page"}>
      <div className={"login-page_logo"}>
        <BgLogo />
      </div>
      <div className={"login-page_content d-flex-column-center-align"}>
        <Logo />
        <div
          className={
            "login-page_content__actions d-flex-center-aligned justify-content-center gap-10"
          }
        >
          <button
            type={"button"}
            className={"d-flex-center-aligned justify-content-center gap-4"}
            onClick={() => handleFetchFreelancer(FreelancerType.FREELANCER)}
          >
            <Freelancer />
            <span>Я фрилансер</span>
          </button>
          <button
            type={"button"}
            className={"d-flex-center-aligned justify-content-center gap-4"}
            onClick={() => handleFetchFreelancer(FreelancerType.CLIENT)}
          >
            <Buyer />
            <span>Я покупатель</span>
          </button>
        </div>
        <p className={"login-page_content--text"}>
          Выберите кем вы сейчас являетесь. Вы ищите <br /> исполнителя или вы хотите найти работу
        </p>
      </div>
    </div>
  )
}

export default LoginPage

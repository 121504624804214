import React, { useCallback } from "react"
import OfferIcon from "../../../assets/images/svg/offers.svg?react"
import "./AddOfferBtn.scss"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../../hooks/redux-hooks/ReduxHooks"
import { resetSingleOffer } from "../../../store/slices/offers"
import { useTranslation } from "react-i18next"
interface IAddOfferBtnProps {
  type: "submit" | "button"
  hideIcon?: boolean
  text?: string
  handleClick?: () => void
}
const AddOfferBtn: React.FC<IAddOfferBtnProps> = ({
  type,
  text,
  handleClick,
  hideIcon = false,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleButtonClick = useCallback(() => {
    if (type === "button") {
      navigate("/add-offer")
      dispatch(resetSingleOffer())
    }
  }, [type])

  return (
    <button
      type={type}
      className={"add-offer-btn d-flex-center-aligned gap-6 justify-content-center"}
      onClick={handleClick ? handleClick : handleButtonClick}
      // disabled
    >
      {!hideIcon ? <OfferIcon /> : null}
      <span>{text ? text : `${t("addOffer")}`}</span>
    </button>
  )
}

export default AddOfferBtn

import React, { useCallback } from "react"
import "./SingleOfferHeading.scss"
import SingleOfferDropdown from "./single-offer-dropdown/SingleOfferDropdown"
import LeftArrow from "../../../assets/images/svg/left-arrow.svg?react"
import PencilIcon from "../../../assets/images/svg/white-pencil.svg?react"
import { useLocation, useNavigate } from "react-router-dom"

const SingleOfferHeading = ({ showEditButton }: { showEditButton: boolean }) => {
  const navigate = useNavigate()
  const { search, pathname } = useLocation()

  const handleOpenEdit = useCallback(() => {
    const searchParams = new URLSearchParams(search)
    searchParams.set("editMode", "true")
    navigate(`${pathname}?${searchParams.toString()}`)
  }, [search, navigate])

  const handleGoBack = useCallback(() => {
    navigate(-1)
  }, [])
  return (
    <div className={"d-flex-space-between-aligned single-offer__heading"}>
      <button className={"d-flex-centered add-offer__heading--go-back"} onClick={handleGoBack}>
        <LeftArrow />
      </button>
      {showEditButton ? (
        <button className={"d-flex-centered add-offer__heading--go-back"} onClick={handleOpenEdit}>
          <PencilIcon />
        </button>
      ) : (
        <SingleOfferDropdown />
      )}
    </div>
  )
}

export default SingleOfferHeading

import React from "react"
import { useAppSelector } from "../../../hooks/redux-hooks/ReduxHooks"
import { getSingleOffer } from "../../../store/slices/offers"
import "./SingleOfferTimeAndPricing.scss"
import { useTranslation } from "react-i18next"

const SingleOfferTimeAndPricing = () => {
  const offer = useAppSelector(getSingleOffer)
  const { t } = useTranslation()
  return (
    <div className={"single-offer-time-and-pricing d-flex-column gap-12"}>
      <div className={"d-flex-space-between-aligned gap-6 offer-pricing"}>
        <p className={"offer-pricing-title"}>{t("price")}:</p>
        <div className={"d-flex-center-aligned gap-6 flex-1 offer-pricing-content"}>
          <div className={"offer-pricing-content--currency d-flex-centered"}>
            <p>$</p>
          </div>
          <div className={"offer-pricing-content--item d-flex-centered"}>
            <p>
              {t("from")} <strong>{offer?.priceFrom}</strong>
            </p>
          </div>
          <div className={"offer-pricing-content--item d-flex-centered"}>
            <p>
              {t("to")} <strong>{offer?.priceTo}</strong>
            </p>
          </div>
        </div>
      </div>
      <div className={"d-flex-space-between-aligned offer-timing "}>
        <p className={"offer-timing-title"}>{t("deadlines")}</p>
        <div className={"d-flex-center-aligned gap-6 offer-timing-content flex-1"}>
          <div className={"flex-1 offer-timing-content--item d-flex-centered"}>
            <p>
              {t("from")} <strong>{offer?.timeFrom}</strong>
            </p>
          </div>
          <div className={"flex-1 offer-timing-content--item d-flex-centered"}>
            <p>
              {t("to")} <strong>{offer?.timeTo}</strong>
            </p>
          </div>
          <div className={"d-flex-centered offer-timing-content--item"}>
            <p>{t("hours")}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleOfferTimeAndPricing

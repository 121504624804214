import React from "react"
import EmptyState from "../../assets/images/svg/my-offers-empty.svg?react"

const OffersEmptyState = () => {
  return (
    <div>
      <div className={"my-offers-page__list"}>
        <div className={"my-offers-page__list--empty"}>
          <EmptyState />
        </div>
      </div>
    </div>
  )
}

export default OffersEmptyState

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IObjectKeys } from "../../../types"
import { AxiosResponse } from "axios"
import api from "../../../api/api"
import { FreelancerType } from "../../../enums"
import { BASE_API_URL } from "../../../constants"

interface Auth {
  loading: boolean
  user: IObjectKeys | null
  freelancer: IObjectKeys | null
  freelancerLoading: boolean
  isFormFocused: boolean
}

interface FreelancerRequest {
  telegramId: string
  firstName: string
  lastName: any
  username: any
  languageCode: any
  allowsWriteToPm: any
}

interface UpdateUserRequest {
  telegramId: string
  userType: FreelancerType
  selectedLanguage?: string
}

const initialState: Auth = {
  loading: true,
  user: null,
  freelancer: null,
  freelancerLoading: true,
  isFormFocused: false,
}

export const checkUser = createAsyncThunk<any, FreelancerRequest>(
  "app/checkUserType",
  async (userData: FreelancerRequest): Promise<any> => {
    const response: AxiosResponse<any> = await api.post(`${BASE_API_URL}freelancers`, userData)
    return response.data
  },
)

export const fetchFreelancerByTelegramId = createAsyncThunk<any, UpdateUserRequest>(
  "app/updateUserType",
  async ({ telegramId, userType }: UpdateUserRequest): Promise<any> => {
    const response: AxiosResponse<any> = await api.patch(
      `${BASE_API_URL}freelancers/${telegramId}`,
      { type: userType },
    )
    return response.data
  },
)

export const fetchFreelancerByTelegramIdFromProfile = createAsyncThunk<any, UpdateUserRequest>(
  "app/updateUserTypeFromProfile",
  async ({ telegramId, userType, selectedLanguage }: UpdateUserRequest): Promise<any> => {
    const response: AxiosResponse<any> = await api.patch(
      `${BASE_API_URL}freelancers/${telegramId}`,
      { type: userType, selectedLanguage },
    )
    return response.data
  },
)

export const fetchFreelancer = createAsyncThunk<any, string>(
  "app/fetchFreelancer",
  async (telegramId: string): Promise<any> => {
    const response: AxiosResponse<any> = await api.get(`${BASE_API_URL}freelancers/${telegramId}`)
    return response.data
  },
)

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(checkUser.pending, (state) => {
        state.loading = true
      })
      .addCase(checkUser.fulfilled, (state, action) => {
        state.loading = false
        state.user = action.payload
      })
      .addCase(checkUser.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchFreelancer.pending, (state) => {
        state.freelancerLoading = true
      })
      .addCase(fetchFreelancer.fulfilled, (state, action) => {
        state.freelancerLoading = false
        state.freelancer = action.payload
      })
      .addCase(fetchFreelancer.rejected, (state) => {
        state.freelancerLoading = false
      })
      .addCase(fetchFreelancerByTelegramId.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchFreelancerByTelegramId.fulfilled, (state, action) => {
        state.loading = false
        state.user = action.payload
      })
      .addCase(fetchFreelancerByTelegramId.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchFreelancerByTelegramIdFromProfile.pending, (state, action) => {
        // state.loading = true
      })
      .addCase(fetchFreelancerByTelegramIdFromProfile.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(fetchFreelancerByTelegramIdFromProfile.rejected, (state) => {
        // state.loading = false
      })
  },
  reducers: {
    setUserInfo: (state, action: PayloadAction<IObjectKeys>) => {
      state.user = action.payload
    },
    resetFreelancer: (state) => {
      state.freelancerLoading = true
      state.freelancer = null
    },
    setIsFormFocused: (state, action: PayloadAction<boolean>) => {
      state.isFormFocused = action.payload
    },
  },
})

export const { setUserInfo, resetFreelancer, setIsFormFocused } = authSlice.actions
export const getUser = (state: IObjectKeys) => state.auth.user
export const getFreelancer = (state: IObjectKeys) => state.auth.freelancer
export const getFreelancerLoading = (state: IObjectKeys) => state.auth.freelancerLoading
export const getIsFormFocused = (state: IObjectKeys) => state.auth.isFormFocused

export default authSlice.reducer

import React from "react"
import { Outlet } from "react-router-dom"
import AuthBgIcon from "../../assets/images/svg/auth-layout-bg-logo.svg?react"
import "./AuthLayout.scss"
import Navigation from "../../components/navigation/Navigation"
import { useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { getIsFormFocused, getUser } from "../../store/slices/auth"
import Loader from "../../components/shared/loader/Loader"

const AuthLayout = () => {
  const isFormFocused = useAppSelector(getIsFormFocused)
  const user = useAppSelector(getUser)
  if (!user.type) {
    return <Loader />
  }
  return (
    <div className={"auth-layout"}>
      <div className={"auth-layout_bg"}>
        <AuthBgIcon />
      </div>
      <div className={"auth-layout_content"}>
        <Outlet />
      </div>
      {!isFormFocused ? <Navigation /> : null}
    </div>
  )
}

export default AuthLayout

import React from "react"
import { IObjectKeys } from "../../../types"
import PostSwipeableCard from "../post-swipable-card/PostSwipableCard"
interface ICardsListProps {
  list: IObjectKeys[]
  isMyOffer?: boolean
  isFav?: boolean
  isHidden?: boolean
}
const CardsList: React.FC<ICardsListProps> = ({ list, isMyOffer, isFav, isHidden }) => {
  return (
    <div className={"d-flex-column gap-12 m-t-16"}>
      {list.map((item, i) => (
        <PostSwipeableCard
          key={i}
          offer={item}
          isMyOffer={isMyOffer}
          isFav={isFav}
          isHidden={isHidden}
        />
      ))}
    </div>
  )
}

export default CardsList

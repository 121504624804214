import React, { useState, useRef, useEffect } from "react"
import MenuIcon from "../../../../assets/images/svg/dropdown-menu-icon.svg?react"
import HideIcon from "../../../../assets/images/svg/single-page-hide-icon.svg?react"
// import ComplaintIcon from "../../../../assets/images/svg/single-page-complaint-icon.svg?react"
// import ExportIcon from "../../../../assets/images/svg/single-page-export-icon.svg?react"
import "./SingleOfferDropdown.scss"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks/ReduxHooks"
import { getSingleOffer, hideOffer } from "../../../../store/slices/offers"
import { useNavigate } from "react-router-dom"

const SingleOfferDropdown: React.FC = () => {
  const offer = useAppSelector(getSingleOffer)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null) // Use ReturnType to avoid TS2503

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  const handleSelect = (option: string) => {
    setIsOpen(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      // Clear any existing timeout before setting a new one
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      // Set a new timeout to handle click outside
      timeoutRef.current = setTimeout(() => {
        handleClickOutside(event)
      }, 0)
    }

    document.addEventListener("mousedown", handleMouseDown)
    return () => {
      document.removeEventListener("mousedown", handleMouseDown)
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const options = [
    {
      label: "Не показывать этот оффер",
      value: 1,
      icon: <HideIcon />,
      action: () => {
        dispatch(hideOffer({ offerId: offer?.id, telegramId: offer?.freelancer?.telegramId })).then(
          () => {
            navigate(-1)
          },
        )
      },
    },
    // { label: "Пожаловаться на оффер", value: 2, icon: <ComplaintIcon /> },
    // { label: "Поделиться оффером", value: 3, icon: <ExportIcon /> },
  ]

  return (
    <div className='single-page-header-dropdown'>
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className={`single-page-header-dropdown__toggle d-flex-centered ${isOpen ? "single-page-header-dropdown__toggle-active" : ""}`}
      >
        <MenuIcon />
      </button>
      <div
        ref={dropdownRef}
        className={`single-page-header-dropdown__menu ${isOpen ? "single-page-header-dropdown__menu--open" : ""}`}
      >
        {options.map((option) => (
          <div
            key={option.value}
            onClick={() => {
              handleSelect(option.label)
              option.action()
            }}
            className='single-page-header-dropdown__menu__item d-flex-center-aligned gap-10'
          >
            {option.icon}
            <p>{option.label}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SingleOfferDropdown

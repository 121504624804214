import React, { useCallback } from "react"
import { Modal } from "react-responsive-modal"
import "./DeleteModal.scss"
import "react-responsive-modal/styles.css"
import TrashBin from "../../../../assets/images/svg/red-trash-bin.svg?react"
import ModalHideIcon from "../../../../assets/images/svg/modal-hide-icon.svg?react"
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks/ReduxHooks"
import { getUser } from "../../../../store/slices/auth"
import { deleteOffer, hideOffer } from "../../../../store/slices/offers"
import { IObjectKeys } from "../../../../types"

interface IDeleteModalProps {
  visible: boolean
  onCloseModal: () => void
  offerId: any
  isHide?: boolean
  freelancer?: IObjectKeys
}

const DeleteModal: React.FC<IDeleteModalProps> = ({
  visible,
  onCloseModal,
  offerId,
  isHide,
  freelancer,
}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const handleYesClick = useCallback(() => {
    if (isHide && freelancer) {
      dispatch(hideOffer({ telegramId: freelancer.telegramId, offerId })).then(() => {
        onCloseModal()
      })
    } else {
      dispatch(deleteOffer({ telegramId: user.telegramId, offerId })).then(() => {
        onCloseModal()
      })
    }
  }, [user, offerId, isHide])

  return (
    <Modal
      open={visible}
      onClose={onCloseModal}
      classNames={{ modal: `delete-modal` }}
      closeIcon={null}
      container={document.getElementById("root")}
      closeOnOverlayClick={false}
    >
      <div className={"delete-modal_body d-flex-column-centered"}>
        {isHide ? <ModalHideIcon /> : <TrashBin />}
        <p className={"delete-modal_body-text"}>
          Вы точно хотите {isHide ? "скрыть" : "удалить"} этот оффер?
        </p>
        <div className={"delete-modal_body_actions d-flex-center-aligned gap-6"}>
          <button className={"delete-modal_body_actions-delete"} onClick={handleYesClick}>
            Да
          </button>
          <button className={"delete-modal_body_actions-close"} onClick={onCloseModal}>
            Нет
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal

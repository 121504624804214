import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { fetchSingleOffer } from "../../store/slices/offers"
import { getUser } from "../../store/slices/auth"
import Loader from "../../components/shared/loader/Loader"
import "./styles.scss"
import SingleOfferHeading from "../../components/single-offer/single-offer-heading/SingleOfferHeading"
import SingleOfferSlider from "../../components/single-offer/single-offer-slider/SingleOfferSlider"
import SingleOfferTimeAndPricing from "../../components/single-offer/time-and-pricing/SingleOfferTimeAndPricing"
import SingleOfferProfile from "../../components/single-offer/single-offer-profile/SingleOfferProfile"
import AddOfferForm from "../../components/add-offer-form/AddOfferForm"
import LeftArrow from "../../assets/images/svg/left-arrow.svg?react"

const SingleOfferPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { offerId } = useParams()
  const { search } = useLocation()
  const isMe = new URLSearchParams(search).get("isMe") === "true"
  const telegramId = new URLSearchParams(search).get("telegramId")
  const isEditMode = new URLSearchParams(search).get("editMode") === "true"
  const [loading, setLoading] = useState(true)

  const handleGoBack = () => {
    navigate(-1)
  }
  useEffect(() => {
    dispatch(fetchSingleOffer({ telegramId: telegramId || "", offerId: offerId as string })).then(
      () => {
        setLoading(false)
      },
    )
  }, [])

  if (loading) {
    return <Loader />
  }
  return (
    <div className={"single-offer"}>
      {!isEditMode ? (
        <div>
          <SingleOfferHeading showEditButton={isMe} />
          <SingleOfferSlider />
          <SingleOfferTimeAndPricing />
          <SingleOfferProfile isMe={isMe} />
        </div>
      ) : (
        <div className={"single-offer__edit"}>
          <div className={"single-offer__edit--heading d-flex-center-aligned gap-16"}>
            <button
              className={"d-flex-centered add-offer__heading--go-back"}
              onClick={handleGoBack}
            >
              <LeftArrow />
            </button>
            <h1>Редактировать оффер</h1>
          </div>
          <AddOfferForm isEditMode={true} />
        </div>
      )}
    </div>
  )
}

export default SingleOfferPage

import React, { useMemo } from "react"
import { useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { getUser } from "../../store/slices/auth"
import { FreelancerType } from "../../enums"
import { NavLink } from "react-router-dom"
import CatalogIcon from "../../assets/images/svg/catalog.svg?react"
import OffersIcon from "../../assets/images/svg/offers.svg?react"
import MoreIcon from "../../assets/images/svg/more.svg?react"
import "./Navigation.scss"
import { useTranslation } from "react-i18next"

const Navigation = () => {
  const user = useAppSelector(getUser)
  const { t } = useTranslation()
  const NAVIGATION_LIST = [
    { id: 0, name: `${t("catalog")}`, icon: <CatalogIcon />, path: "/catalog" },
    { id: 1, name: `${t("offers")}`, icon: <OffersIcon />, path: "/offers" },
    { id: 2, name: `${t("more")}`, icon: <MoreIcon />, path: "/more" },
  ]
  const navigationList = useMemo(() => {
    if (user.type && user.type === FreelancerType.FREELANCER) {
      return NAVIGATION_LIST
    } else {
      return NAVIGATION_LIST.filter((item) => item.id !== 1)
    }
  }, [user.type, NAVIGATION_LIST])

  return (
    <div className={"navigation d-flex-center-aligned justify-content-center"}>
      <div className={"navigation-content d-flex-center-aligned gap-32"}>
        {navigationList.map((item) => (
          <NavLink
            key={item.id}
            to={item.path}
            className={({ isActive }) =>
              `d-flex-column-centered gap-4 navigation-link ${isActive ? "navigation-active-link" : ""}`
            }
          >
            {item.icon}
            <p>{item.name}</p>
          </NavLink>
        ))}
      </div>
    </div>
  )
}

export default Navigation

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../../api/api"
import { AxiosResponse } from "axios"
import { BASE_API_URL } from "../../../constants"

interface FileUploadState {
  imagesLoading: boolean
  uploadError: string | null
}

const initialState: FileUploadState = {
  imagesLoading: false,
  uploadError: null,
}

export const uploadImages = createAsyncThunk(
  "file/upload",
  async (file: File, { rejectWithValue }) => {
    try {
      const formData = new FormData()
      formData.append("file", file)

      const response = await api.post<AxiosResponse<any>>(`${BASE_API_URL}files`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })

      return response.data
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message
      return rejectWithValue(errorMessage)
    }
  },
)
const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(uploadImages.pending, (state) => {
        state.imagesLoading = true
        state.uploadError = null
      })
      .addCase(uploadImages.fulfilled, (state) => {
        state.imagesLoading = false
      })
      .addCase(uploadImages.rejected, (state, action) => {
        state.imagesLoading = false
        state.uploadError = action.error.message || "Failed to upload images"
      })
  },
  reducers: {},
})

export const selectImagesLoading = (state: { fileUpload: FileUploadState }) =>
  state.fileUpload.imagesLoading
export const selectUploadError = (state: { fileUpload: FileUploadState }) =>
  state.fileUpload.uploadError

export default fileUploadSlice.reducer

import React, { useCallback, useEffect, useState } from "react"
import { BASE_URL } from "../../constants"
import VerifiedIcon from "../../assets/images/svg/verify-icon.svg?react"
import ContactIcon from "../../assets/images/svg/contact-icon.svg?react"
import GuardIcon from "../../assets/images/svg/profile-cover.svg?react"
import LeftArrow from "../../assets/images/svg/left-arrow.svg?react"
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import {
  fetchFreelancer,
  getFreelancer,
  getFreelancerLoading,
  resetFreelancer,
} from "../../store/slices/auth"
import "./styles.scss"
import {
  fetchMyOffers,
  getCatalogOffers,
  getCatalogOffersLoading,
  getRefetchOffers,
  resetCatalogOffers,
} from "../../store/slices/offers"
import Loader from "../../components/shared/loader/Loader"
import CardsList from "../../components/shared/card-list/CardsList"
import OffersEmptyState from "../../components/offers-empty-state/OffersEmptyState"
import { useLocation, useNavigate } from "react-router-dom"
import LoadMoreButton from "../../components/shared/load-more-btn/LoadMoreButton"

const OtherOffersPage = () => {
  const freelancer = useAppSelector(getFreelancer)
  const freelancerLoading = useAppSelector(getFreelancerLoading)
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const catalogOffers = useAppSelector(getCatalogOffers)
  const catalogOffersLoading = useAppSelector(getCatalogOffersLoading)
  const [page, setPage] = useState(1)
  const telegramId = new URLSearchParams(search).get("telegramId")
  const [newOffers, setNewOffers] = useState(catalogOffers)
  const [newOffersLoading, setNewOffersLoading] = useState(false)
  const perPage = 3
  const refetchOffers = useAppSelector(getRefetchOffers)
  const navigate = useNavigate()
  const handleLoadMore = useCallback(() => {
    setNewOffersLoading(true)
    setPage((prevPage: any) => prevPage + 1)
  }, [page])

  const handleFetchOffers = useCallback((pageNum: number) => {
    dispatch(
      fetchMyOffers({ telegramId: telegramId || "", type: "public", page: pageNum, perPage }),
    ).then((res) => {
      setNewOffersLoading(false)
      setNewOffers(res.payload.offers)
    })
  }, [])

  useEffect(() => {
    handleFetchOffers(page)
  }, [page, dispatch, telegramId])

  useEffect(() => {
    dispatch(fetchFreelancer(telegramId || ""))
    return () => {
      dispatch(resetCatalogOffers())
      dispatch(resetFreelancer())
    }
  }, [])
  useEffect(() => {
    if (refetchOffers) {
      handleFetchOffers(1)
    }
  }, [refetchOffers])
  if (freelancerLoading || (catalogOffersLoading && !catalogOffers.length)) {
    return <Loader />
  }
  return (
    <div className={"profile-page other-offers-page"}>
      <div className={"d-flex-space-between-aligned profile-page__heading"}>
        <div className={"d-flex-center-aligned gap-16"}>
          <button
            className={"d-flex-centered add-offer__heading--go-back other-offers-go-back"}
            onClick={() => navigate(-1)}
          >
            <LeftArrow />
          </button>
          <div className={"d-flex-center-aligned profile-page__heading--info gap-8"}>
            <VerifiedIcon />
            <p>{freelancer.username}</p>
          </div>
        </div>
        <button
          className={"d-flex-centered profile-page__heading--contact"}
          onClick={() => {
            window.location.href = `https://t.me/the010012`
          }}
        >
          <ContactIcon />
        </button>
      </div>
      <div className={"profile-page__picture other-offers-page-picture d-flex-column"}>
        <div className={"profile-page__picture--cover"}>
          <GuardIcon />
        </div>
        <div className={"profile-page__picture--img"}>
          <img src={`${BASE_URL}/${freelancer?.avatarUrl}`} alt='avatar' />
        </div>
      </div>
      <div className={"profile-page__content d-flex-column other-offers-page-content"}>
        <div className={"profile-page__content__list d-flex-column"}>
          {catalogOffers.length ? <CardsList list={catalogOffers} /> : <OffersEmptyState />}
        </div>
        {newOffers.length && newOffers.length === perPage ? (
          <div className={"container-padding"}>
            <LoadMoreButton handleClick={handleLoadMore} isLoading={newOffersLoading} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default OtherOffersPage

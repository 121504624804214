import React from "react"
import { Modal } from "react-responsive-modal"
import "./ReasonModal.scss"
import "react-responsive-modal/styles.css"

interface IDeleteModalProps {
  visible: boolean
  onCloseModal: () => void
  reason: string
}

const ReasonModal: React.FC<IDeleteModalProps> = ({ visible, onCloseModal, reason }) => {
  return (
    <Modal
      open={visible}
      onClose={onCloseModal}
      classNames={{ modal: `reason-modal` }}
      closeIcon={null}
      container={document.getElementById("root")}
      closeOnOverlayClick={false}
    >
      <div className={"reason-modal_body d-flex-column-centered"}>
        <h2 className={"reason-modal_body-title"}>Причина</h2>
        <p className={"reason-modal_body-text"}>{reason}</p>
        <div className={"reason-modal_body_actions d-flex-center-aligned gap-6"}>
          <button className={"reason-modal_body_actions-close"} onClick={onCloseModal}>
            Закрыть
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ReasonModal

import React from "react"
import "./Loader.scss"
import RingLoader from "./ring-loader/RingLoader"

const Loader = () => {
  return (
    <div className={"loader"}>
      <RingLoader />
    </div>
  )
}

export default Loader

import React from "react"
import "./LoadMoreButton.scss"
import RingLoader from "../loader/ring-loader/RingLoader"
import { useTranslation } from "react-i18next"

interface ILoadMoreButton {
  handleClick: () => void
  isLoading: boolean
}
const LoadMoreButton: React.FC<ILoadMoreButton> = ({ handleClick, isLoading }) => {
  const { t } = useTranslation()
  return (
    <button type={"button"} onClick={handleClick} className={"load-more-btn d-flex-centered gap-8"}>
      {isLoading ? <RingLoader /> : null}
      <span>{t("loadMore")}</span>
    </button>
  )
}

export default LoadMoreButton

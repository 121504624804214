import React, { useCallback, useEffect, useState } from "react"
import PageHeadingStatus from "../../components/page-heading-status/PageHeadingStatus"
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { getUser } from "../../store/slices/auth"
import {
  fetchMyOffers,
  getCatalogOffers,
  getCatalogOffersLoading,
  getRefetchOffers,
  resetCatalogOffers,
} from "../../store/slices/offers"
import Loader from "../../components/shared/loader/Loader"
import CardsList from "../../components/shared/card-list/CardsList"
import LoadMoreButton from "../../components/shared/load-more-btn/LoadMoreButton"
import OffersEmptyState from "../../components/offers-empty-state/OffersEmptyState"

const PageWithStatuses = ({ status }: { status: "hidden" | "favorite" }) => {
  const refetchOffers = useAppSelector(getRefetchOffers)
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const offersLoading = useAppSelector(getCatalogOffersLoading)
  const offers = useAppSelector(getCatalogOffers)
  const [page, setPage] = useState(1)
  const [newOffers, setNewOffers] = useState(offers)
  const [newOffersLoading, setNewOffersLoading] = useState(false)
  const perPage = 3
  const handleFetchOffers = useCallback(
    (pageNum: number) => {
      dispatch(
        fetchMyOffers({ telegramId: user.telegramId, type: status, page: pageNum, perPage }),
      ).then((res) => {
        setNewOffersLoading(false)
        setNewOffers(res.payload.offers)
      })
    },
    [status],
  )

  useEffect(() => {
    handleFetchOffers(page)
  }, [page, user.telegramId, perPage, dispatch])

  const handleLoadMore = () => {
    setNewOffersLoading(true)
    setPage((prevPage: any) => prevPage + 1)
  }

  useEffect(() => {
    return () => {
      dispatch(resetCatalogOffers())
    }
  }, [])

  useEffect(() => {
    if (refetchOffers) {
      handleFetchOffers(1)
    }
  }, [refetchOffers])
  if (offersLoading && offers.length) {
    return <Loader />
  }
  return (
    <div className={"page-bottom-padding page-top-padding"}>
      <PageHeadingStatus type={status} />
      <CardsList list={offers} isHidden={status === "hidden"} isFav={status === "favorite"} />
      {!offers.length ? (
        <div className={"container-padding"}>
          <OffersEmptyState />
        </div>
      ) : null}
      {newOffers.length && newOffers.length === perPage ? (
        <div className={"container-padding"}>
          <LoadMoreButton handleClick={handleLoadMore} isLoading={newOffersLoading} />
        </div>
      ) : null}
    </div>
  )
}

export default PageWithStatuses

import { combineReducers, configureStore } from "@reduxjs/toolkit"
import authSlice from "./slices/auth/index"
import categoriesSlice from "./slices/categories/index"
import fileUploadSlice from "./slices/file-upload/index"
import offersSlice from "./slices/offers/index"

const rootReducer = combineReducers({
  auth: authSlice,
  categories: categoriesSlice,
  fileUpload: fileUploadSlice,
  offers: offersSlice,
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]

export const store = setupStore()

import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { IObjectKeys } from "../../types"
import "./Categories.scss"
import { fetchCategories, fetchSubCategories, getCategories } from "../../store/slices/categories"

const Categories = ({
  subCategoriesRef,
}: {
  subCategoriesRef: React.RefObject<HTMLDivElement>
}) => {
  const dispatch = useAppDispatch()
  const categories = useAppSelector(getCategories)
  const [activeCategoryId, setActiveCategoryId] = useState<number | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchCategories({ withSubcategories: false }))
    }

    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (activeCategoryId !== null) {
      dispatch(fetchSubCategories(activeCategoryId))
    }
  }, [dispatch, activeCategoryId])

  useEffect(() => {
    if (categories.length && activeCategoryId === null) {
      setActiveCategoryId(0)
    }
  }, [categories, activeCategoryId])

  const handleCategoryClick = (id: number) => {
    setActiveCategoryId(id)

    if (subCategoriesRef.current) {
      subCategoriesRef.current.scrollLeft = 0
    }
  }
  return (
    <div className={"categories d-flex-center-aligned gap-8"}>
      {categories?.map((category: IObjectKeys) => (
        <button
          key={category.id}
          id={category.id}
          className={`categories__btn ${activeCategoryId === category.id ? "categories__btn-active" : ""}`}
          onClick={() => handleCategoryClick(category.id)}
        >
          {category.name}
        </button>
      ))}
    </div>
  )
}

export default Categories

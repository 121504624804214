import React, { useMemo, useState, useRef, useEffect } from "react"
import ShowMoreIcon from "../../../assets/images/svg/show-more-icon.svg?react"
import { useAppSelector } from "../../../hooks/redux-hooks/ReduxHooks"
import { getSingleOffer } from "../../../store/slices/offers"
import { BASE_URL } from "../../../constants"
import "./SingleOfferSlider.scss"
import { useTranslation } from "react-i18next"

const SingleOfferSlider = () => {
  const singleOffer = useAppSelector(getSingleOffer)
  const [showMoreText, setShowMoreText] = useState(false)
  const [isButtonVisible, setIsButtonVisible] = useState(false)
  const descRef = useRef<HTMLParagraphElement | null>(null)
  const { t } = useTranslation()
  const sliderImagesList = useMemo(() => {
    if (Array.isArray(singleOffer?.offerPortfolio) && singleOffer?.thumbnail) {
      return [singleOffer.thumbnail, ...singleOffer.offerPortfolio].filter((item) => item)
    }
    return []
  }, [singleOffer])

  useEffect(() => {
    if (descRef.current) {
      const lineHeight = parseFloat(getComputedStyle(descRef.current).lineHeight)
      const maxHeight = lineHeight * 7
      setIsButtonVisible(descRef.current.scrollHeight > maxHeight)
    }
  }, [singleOffer?.description])

  return (
    <div className={"single-offer-slider"}>
      <h2 className={"single-offer-slider--title"}>{singleOffer?.title}</h2>
      <div className={"single-offer-slider__list d-flex-center-aligned gap-8"}>
        {sliderImagesList.map((image) => (
          <div key={image.id} className={"single-offer-slider__list--item"}>
            <img src={`${BASE_URL}${image.url}`} alt={image.url} />
          </div>
        ))}
      </div>
      <div className={"single-offer-slider__desc"}>
        <p
          ref={descRef}
          className={`single-offer-slider__desc--text ${!showMoreText ? "single-offer-slider__desc--text-clamped" : ""}`}
        >
          {singleOffer?.description}
        </p>
        {isButtonVisible && (
          <div className={"d-flex-centered m-t-16"}>
            <button
              className={`single-offer-slider__desc--show-more d-flex-center-aligned gap-6 ${showMoreText ? "single-offer-slider__desc--show-more-close" : ""}`}
              onClick={() => setShowMoreText((prevState) => !prevState)}
            >
              <span>{!showMoreText ? t("expandDesc") : t("collaoseDesc")}</span>
              <ShowMoreIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default SingleOfferSlider

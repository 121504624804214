import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import LoginPage from "../pages/login-page"
import AuthLayout from "../layouts/auth-layout/AuthLayout"
import MainPage from "../pages/main-page"
import BaseLayout from "../layouts/base-layout/BaseLayout"
import ProfilePage from "../pages/profile"
import MyOffersPage from "../pages/my-offers"
import { useAppSelector } from "../hooks/redux-hooks/ReduxHooks"
import { getUser } from "../store/slices/auth"
import { FreelancerType } from "../enums"
import AddOfferPage from "../pages/add-offer"
import SingleOfferPage from "../pages/single-offer"
import OtherOffersPage from "../pages/other-offers"
import HiddenPage from "../pages/hidden"
import FavoritesPage from "../pages/favorites"
import FreelancerProfile from "../pages/freelancer-profile"

const Routers = () => {
  const user = useAppSelector(getUser)
  const { pathname } = useLocation()

  return (
    <Routes>
      <Route element={<BaseLayout />}>
        <Route path='/' element={<LoginPage />} />
        <Route element={<AuthLayout />}>
          <Route index path={"/catalog"} element={<MainPage />} />
          <Route index path={"/more"} element={<ProfilePage />} />
          <Route
            index
            path={"/offers"}
            element={
              user?.type === FreelancerType.CLIENT && pathname === "/offers" ? (
                <Navigate to={"/catalog"} />
              ) : (
                <MyOffersPage />
              )
            }
          />
          <Route path={"/offers/:offerId"} element={<SingleOfferPage />} />
          <Route path={"/other-offers"} element={<OtherOffersPage />} />
          <Route
            index
            path={"/add-offer"}
            element={
              user?.type === FreelancerType.CLIENT && pathname === "/add-offer" ? (
                <Navigate to={"/catalog"} />
              ) : (
                <AddOfferPage />
              )
            }
          />
          <Route index path={"/hidden"} element={<HiddenPage />} />
          <Route index path={"/favorites"} element={<FavoritesPage />} />
          <Route index path={"/freelancer-profile"} element={<FreelancerProfile />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default Routers

import React, { useMemo, useEffect, useRef, useCallback } from "react"
import RuIcon from "../../assets/images/svg/ru-flag.svg?react"
import EngIcon from "../../assets/images/svg/usa-flag.svg?react"
import "./LanguageSelect.scss"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks/ReduxHooks"
import { fetchFreelancerByTelegramIdFromProfile, getUser } from "../../store/slices/auth"

// Define the structure of a language option
type LanguageOption = {
  icon: JSX.Element
  lang: string
}

const LanguageSelect: React.FC = () => {
  const selectedLanguageValue = localStorage.getItem("language") || "ru"
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false)
  const { i18n } = useTranslation()
  const user = useAppSelector(getUser)
  const dispatch = useAppDispatch()
  const handleButtonClick = useCallback(
    (lang: string) => {
      dispatch(
        fetchFreelancerByTelegramIdFromProfile({
          userType: user.type,
          telegramId: user.telegramId,
          selectedLanguage: lang,
        }),
      )
    },
    [user],
  )
  const languagesArray: LanguageOption[] = [
    { icon: <RuIcon />, lang: "ru" },
    { icon: <EngIcon />, lang: "en" },
  ]

  const selectedLanguage = useMemo<LanguageOption | undefined>(() => {
    return languagesArray.find((item) => item.lang === selectedLanguageValue)
  }, [selectedLanguageValue, languagesArray])

  const languageSelectRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (languageSelectRef.current && !languageSelectRef.current.contains(event.target as Node)) {
        setIsExpanded(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    i18n.changeLanguage(selectedLanguageValue ? selectedLanguageValue : "ru")
  }, [selectedLanguageValue])
  return (
    <div ref={languageSelectRef} className='language-select'>
      <button
        className={`language-select-btn d-flex-centered ${isExpanded && "language-select-btn--expanded"}`}
        onClick={() => setIsExpanded((prevState) => !prevState)}
      >
        {selectedLanguage?.icon}
      </button>
      {isExpanded && (
        <div className={`language-select-dropdown`}>
          {languagesArray.map((lang) => (
            <button
              key={lang.lang}
              onClick={() => {
                handleButtonClick(lang.lang)
                localStorage.setItem("language", lang.lang)
                setIsExpanded(false)
              }}
            >
              {lang.icon}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default LanguageSelect

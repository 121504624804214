import React from "react"
import HeartIcon from "../../../../assets/images/svg/tab-heart-icon.svg?react"
import HideIcon from "../../../../assets/images/svg/tab-hide-icon.svg?react"
import BrokenHeartIcon from "../../../../assets/images/svg/tab-broken-heart-icon.svg?react"

const BaseOfferTabs = ({ isFavorite, isFavPage }: { isFavorite: boolean; isFavPage: boolean }) => {
  return (
    <div>
      <>
        <div className='swipe-right-tab d-flex-column-centered my-offers-base-tab-right'>
          {isFavorite ? <BrokenHeartIcon /> : <HeartIcon />}
        </div>
        <div className='swipe-left-tab  d-flex-column-centered my-offers-base-tab-left'>
          {isFavPage ? <BrokenHeartIcon /> : <HideIcon />}
        </div>
      </>
    </div>
  )
}

export default BaseOfferTabs
